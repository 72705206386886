.button {
  background: transparent;
  padding: 6px;
  border-radius: 6px;

  &:hover {
    background-color: #f9f9f9;
  }

  &:active {
    background-color: #f3f3f3;
  }
}
