.file {
  display: flex;
  align-items: center;
  position: relative;
  font-family: var(--font-family);
  font-weight: 400;
  width: 100%;
  border: var(--border-default);
  padding: 16px;
  padding-right: 12px;
  border-radius: 10px;

  .meta {
    transition: all 300ms;
    opacity: 0;
  }

  &:hover {
    .controls,
    .meta {
      opacity: 1;
    }
  }

  .icon {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    overflow: hidden;
    border: var(--border-default);
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: calc(100% - 48px);
    padding-left: 16px;
  }

  .title,
  .size {
    user-select: none;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .size {
    font-size: 10px;
    opacity: 0.6;
  }
}

.selected {
  position: relative;

  &:after {
    position: absolute;
    content: " ";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    border-radius: 10px;
    border: 2px solid #7a7aff;
    outline: 4px solid #f0f0ff;
    z-index: 2;
  }
}

.download {
  text-decoration: none;

  &:hover {
    background-color: #fcfcfe;

    .icon {
      background-color: #fcfcfe;
    }
  }
}
