.container {
  position: relative;
  width: 100%;
  height: var(--editor-content-height);
  overflow-y: auto;
}

.pageWrapper {
  display: block;
  position: relative;

  width: 100%;
  height: var(--editor-page-container-height);
  overflow-y: scroll;
}

.page {
  position: relative;
  max-width: var(--editor-page-container-width);
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding-top: 30px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 0 auto;
  transform: translate(0, 0, 0); // GPU ✅
  border-radius: 20px;

  .editorContent {
    min-height: 100%;
    padding-bottom: 200px;
  }
}
