.spinner {
  // position: relative;

  // left: calc(50% - 0.5rem);

  width: 16px;
  height: 16px;

  border-top: 2px solid #000;
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: rotation 0.8s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
