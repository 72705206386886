.wrapper {
  position: relative;
}

.button {
  font-family: inherit;
  font-size: inherit;
  padding: 8px;
  margin: 0;
  cursor: pointer;
  border: 0;
  color: #63627d;
  transition: color 200ms;
  border-radius: 8px;

  position: relative;
  box-sizing: border-box;
  background: #f9f9f9;
  color: #474e58;

  &:hover:not(.active) {
    color: #4c5367;
  }
}
