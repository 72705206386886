.container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.imageWrapper {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
}
