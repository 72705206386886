.post {
  position: relative;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 300ms;
  border: var(--border-default);

  &.draft {
    border-style: dashed;
  }

  &:hover {
    border-color: #fafafa;
    box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.08),
      0 5px 15px 0 rgba(0, 0, 0, 0.04);
    transform: translateY(-4px);
  }
}

.title {
  line-height: 1.2;
  font-weight: 600;
  font-size: 20px;
}

.files {
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 1;

  color: #fff;
  font-weight: 600;

  padding: 12px;
}

.content {
  max-height: 200px;
  flex: 1;
}

.asset {
  width: 24px;
  height: 24px;
  border-radius: 6px;
}

.date {
  font-size: 10px;
  opacity: 0.8;
}

.tooltip {
  border-radius: 100px;
  padding: 2px 6px;
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  background-color: #f2f2f5;
}
