.overlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.08);
  z-index: 99;
  padding: 16px;
}

.modal {
  flex: 1;
  background: #fff;
  outline: none;
  box-shadow: 0 20px 40px 0 rgba(0, 35, 90, 0.1),
    0 0 2px 0 rgba(0, 35, 90, 0.06);
  border-radius: 8px;
  padding: 16px;
  max-width: 1000px;
  height: auto;
}
