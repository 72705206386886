@mixin looks-like-input {
  -webkit-appearance: none;
  border-radius: 3px;
  border: 1px solid rgb(218, 222, 237);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  color: var(--primary-text-color);
  display: block;
  font-family: var(--font-family);
  font-size: 1em;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 8px 12px;
  transition: border-color 200ms;
  width: 100%;

  &::placeholder {
    color: var(--placeholder-color) !important;
  }

  &::-webkit-input-placeholder {
    color: var(--placeholder-color) !important;
  }

  &:not(:disabled):hover {
    border-color: var(--primary-color-30);
  }

  &:focus {
    border-color: var(--primary-color) !important;
  }
}
