.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resizable {
  position: relative;
  border: 2px solid #fff;
  border-radius: 4px;
  border-top-left-radius: 0;

  transition: background-color 200ms, border 200ms;

  &:hover:not(.selected) {
    background-color: #f0f0ff;
    border: 2px solid #7a7aff;

    &::after {
      display: block;
      position: absolute;
      content: " ";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      pointer-events: none;
    }
  }

  &.selected {
    background-color: rgba(#f0f0ff, 0.5);
    border: 2px solid #7a7aff;
    outline: 4px solid #f0f0ff;
  }
}

.menu {
  display: block;
  position: absolute;
  background: #f0f0ff;
  color: #7a7aff;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 1;
  left: 50%;
  top: -4px;
  transform: translateY(-100%) translateX(-50%);

  .menuItem {
    padding: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    cursor: pointer;
  }
}
