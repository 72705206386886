.list {
  width: 100%;
  align-items: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.editButton {
  padding: 20px;
  border-radius: 50px;
}
