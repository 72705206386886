.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.head:not(:first-child) {
    padding-top: 40px;

    &::before {
      display: block;
      position: absolute;
      width: 1px;
      height: 40px;
      background: #ddd;
      content: "";
      top: 0;
      left: calc(50% - 1px);
    }
  }

  &.tail {
    padding-bottom: 40px;

    &::after {
      display: block;
      position: absolute;
      width: 1px;
      height: 40px;
      background: #ddd;
      content: "";
      bottom: 0;
      left: calc(50% - 1px);
    }
  }
}

.timestamp {
  font-weight: 900;
  text-align: center;
  padding: 8px;
  font-size: 20px;
  min-width: 140px;
  border-radius: 100px;
  border: 0;
}
