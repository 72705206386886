.plusButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  background-color: #f3f3f3;
  color: var(--primary-text-color);

  border: 0;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
}
