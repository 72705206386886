.ReactModal__Content {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(-20px);
}
