.react-datepicker {
  border: 0;
  outline: none;
  box-shadow: 0 5px 40px 0 rgba(0, 35, 90, 0.1), 0 0 2px 0 rgba(0, 35, 90, 0.06);
}

.react-datepicker__header {
  background-color: #fff;
}

.react-datepicker__day {
  outline: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month {
  cursor: pointer;
}

.react-datepicker-popper {
  z-index: 3;
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #fff;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #fff;
  border: 0;
  box-shadow: 0 5px 40px 0 rgba(0, 35, 90, 0.1), 0 0 2px 0 rgba(0, 35, 90, 0.06);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f7f6fa;
  background-color: #fff;
  color: #333;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selecte {
  color: #333;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #f7f6fa;
  //   color: #55bd7e;
  font-weight: 600;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f7f6fa;
}

.react-datepicker__header {
  border: 0;
}

.react-datepicker__current-month {
  padding-bottom: 12px;
}
