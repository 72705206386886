.tag {
  display: inline-block;
  color: white;

  font-size: 1em;
  font-weight: 500;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 600;
  background: orange;
  padding: 4px 8px;
  border-radius: 10px;
  color: white;
}

.text {
  line-height: 1;
}
