.wrapper {
  font-family: var(--font-family);
  margin-top: 1em;
  margin-bottom: 1em;
}

.selected {
  outline: 2px solid #7a7aff;
}

.tools {
  border: 1px dashed #ddd;
  border-radius: 12px;

  padding-right: 8px;
}

.input {
  width: 100%;
  outline: none;
  font-family: var(--font-family);
  font-size: 16px;
  border: 0;
  background-color: transparent;
  color: var(--primary-text-color);
  padding: 20px;

  &::placeholder {
    font-weight: 500;
    color: var(--placeholder-color);
  }
}
