.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f2f2f5;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .header {
    width: 100%;
    text-align: center;
    padding: 20px;
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .body {
    display: flex;
    flex: 1;
    max-width: 600px;
    width: 100%;
    height: 100%;

    .content {
      width: 100%;
    }
  }
}
