.container {
  display: block;
  position: relative;
}

.primaryButton {
  padding: 20px;
  border-radius: 50px;
  cursor: s-resize !important;
}
