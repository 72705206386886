.icon {
  display: flex;
  width: 48px;
  height: 48px;
  font-weight: 800;

  color: #fff;
  user-select: none;
  align-items: center;
  justify-content: center;
  line-height: 1;

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.small {
  width: 24px;
  height: 24px;
  font-size: 10px;
}

.medium {
  width: 36px;
  height: 36px;
  font-size: 12px;
}

.inline {
  display: inline-flex;
}
