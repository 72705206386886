.wrapper {
  width: 100%;
  padding: 64px 40px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    padding: 20px;
  }

  .body {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;

    .header {
      width: 100%;

      .title {
        // padding: 012px;
        // padding-left: 16px;
      }
    }

    .content {
      width: 100%;
      padding: 16px 0;
    }
  }
}
