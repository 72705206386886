.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  // min-width: var(--editor-header-height);
  height: var(--editor-header-height);
  cursor: pointer;
  text-align: center;

  // padding-right: 8px;
  // padding-left: 8px;
  // min-width: 80px;

  &:hover {
    color: #000;
  }
}
