.previewLabel {
  color: #46c682;
  padding-right: 20px;
  font-weight: 600;
}

.header {
  display: flex;
  width: 100%;
  height: 60px;
}

.button {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 36px;

  color: #8a9199;
  cursor: pointer;

  transition: all 200ms;

  &:hover {
    color: var(--primary-text-color);
    background-color: #f3f3f3;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.active {
    background-color: var(--primary-text-color);
    color: #f3f3f3;
  }
}
