.component {
  position: relative;
  background: #fff;
}

.frame {
  display: flex;
  position: relative;
  flex-direction: column;

  width: 100%;
  max-width: 1000px;
  margin: 0 auto 60px;
  border-radius: 8px;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 25px 50px -12px;
}

.content {
  position: relative;
  padding: 40px;
}

.toolbar {
  position: relative;
  border-top: var(--border-default);
  width: 100%;
}

.toolbarButtonContainer {
  display: flex;
  justify-content: center;
  padding: 12px;
}

.toolbarButton {
  display: inline-flex;
  background-color: #f9f9f9;
  border-radius: 14px;
  padding: 6px;
  cursor: pointer;
}

.toolbarContent {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
