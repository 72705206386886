.answer:not(:first-of-type) {
  padding-top: 20px;
}

.content {
  width: 100%;
  padding-bottom: 20px;
}

.answer:not(:last-of-type) .content {
  border-bottom: var(--border-default);
}

.list {
  max-height: 500px;
  overflow: auto;
}
