@import "styles/mixins.scss";

.container {
  position: relative;
  @include looks-like-input;
}

.required {
  opacity: 0.6;
}

.error {
  box-shadow: inset 0 0 0 1px var(#d0021c, var(#d0021c));
}

.errorMessage {
  color: red;
}

.label {
  font-weight: 500;
  line-height: 1.5;
}
