.anchor {
  display: block;
  position: relative;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #eee;
  font-weight: 400;
  text-decoration: none;

  background-color: #fdfdff;

  &:hover {
    background-color: #fcfcfe;

    .host {
      text-decoration: underline;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.description {
  color: var(--secondary-text-color);
  font-size: 14px;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 12px;
}

.host {
  font-size: 12px;
  opacity: 0.5;
  text-align: right;
}
