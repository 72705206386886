.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #fff;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;

  border: var(--border-default);
}
