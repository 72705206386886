.link {
  display: inline-block;
  cursor: pointer;

  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.separator {
  padding: 0 8px;

  &:before {
    content: "/";
  }
}

.component {
  display: block;
  margin-left: 12px;
  padding: 2px;
  padding-right: 12px;
  border-radius: 8px;
  border: 3px solid #fff;
  background: #fff;

  &:hover {
    background-color: #fbfbfb;
  }
}

.title {
  white-space: nowrap;
}
