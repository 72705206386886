.textarea {
  width: 100%;
  font-size: var(--font-size);
  padding: 8px;
  box-shadow: inset 0 0 0 1px var(--border, #d0d0df);
  border: 0;
  border-radius: 6px;
  outline: none;
  font-family: inherit;
  resize: unset;
  line-height: 1.5;
  height: 36px;

  &::placeholder {
    color: var(--placeholder-color);
    transition: color 0.2s;
  }
}

.component {
  width: 100%;
  font-family: var(--font-family);
}
