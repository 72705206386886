.message {
  flex: 1;
  padding: 12px;
  padding-right: 6px;
  border-radius: 6px;
  background-color: #fafafa;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-right: 16px;
}

.comment {
  margin-bottom: 14px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  justify-content: space-between;
  height: 32px;
}
