.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 10px;
  width: 100%;

  &:hover {
    // cursor: pointer;
  }

  .body {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 240px;
    width: 100%;
  }
}

.menu {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 6px;
  background-color: #f9f9f9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;

  .name {
    overflow: hidden;
    text-overflow: ellipsis;

    width: 100%;
  }
}
