.project {
  position: relative;
}

.footer {
  padding: 6px 2px;
  padding-top: 10px;
}

.role {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  color: var(--secondary-text-color);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.subtext {
  font-size: 13px;
  font-weight: 400;
  color: var(--secondary-text-color);
}
