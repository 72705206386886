@import "styles/mixins.scss";

.textarea {
  position: relative;
  resize: none;
  font-size: var(--font-size);

  &::-webkit-scrollbar {
    display: none;
  }

  @include looks-like-input;

  &:not(.headless) {
    height: 36px;
  }
}

.label {
  font-weight: 500;
  line-height: 1.5;
}

.container {
  position: relative;

  &.maxLength::after {
    position: absolute;
    content: attr(data-length-allowed);
    right: 6px;
    bottom: 4px;
    font-size: 10px;
    color: var(--placeholder-color);
  }
}

.errorMessage {
  color: red;
}
