.container {
  position: relative;
  width: 100%;
  max-width: 600px;

  &:hover {
    .hint {
      opacity: 1;
    }
  }
}

.asset {
  display: flex;
  position: relative;
  margin: 4px;
  font-weight: 400;
  border-radius: 8px;
  border: var(--border-default);
  padding: 12px;
  background: white;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(49, 49, 93, 0.05),
      0 2px 7px 0 rgba(0, 0, 0, 0.03);
  }
}

.link {
  text-transform: none;
  text-decoration: none;
}

.hint {
  opacity: 0;
  transition: all 300ms;
}

.details {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 100px);
  flex: 1;
  padding-left: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 14px;
  border-left: var(--border-default);
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;
}

.footer {
  width: 100%;
  font-size: 10px;
  opacity: 0.8;
}

.size {
  text-align: left;
}

.date {
  text-align: right;
}

.extension {
  line-height: 1;
  text-transform: uppercase;
}

.menu {
}
