.dialog {
  padding: 0;
  max-width: 480px;
  // max-height: 100%;

  padding-top: 8px;
  padding-bottom: 8px;
}

.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
}

.element {
  display: block;
  padding: 16px 24px;
}

.button {
  height: 42px !important;
}

.description {
  padding: 8px 24px;
  color: var(--secondary-text-color);
}
