.component {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.title {
  padding-bottom: 20px;
}

.content {
  padding-top: 10px;
}
