.list {
  padding: 6px;
  margin: 0;
}

.item {
  margin: 0;
  list-style: none;
  white-space: nowrap;
  padding: 6px 12px;
  min-width: 160px;

  &:hover {
    background-color: #f6f8fa;
  }
}

.scrollable {
  max-height: 300px;
  overflow: auto;
}
