.container {
  background-color: #fff;
  border-bottom: var(--editor-border-default);
  height: 60px;
  width: var(--editor-page-container-width);
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bar {
  opacity: 1;
  transition: opacity 300ms;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
