.buttons {
  display: inline-flex;
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  padding: 4px 4px;
  background: #fff;
}

.button {
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  padding: 8px 20px;
  margin: 0;
  cursor: pointer;
  border: 0;
  color: #63627d;
  transition: color 200ms;
  border-radius: 20px;

  &:hover:not(.active) {
    color: #4c5367;
  }
}

.active {
  position: relative;
  box-sizing: border-box;
  font-weight: 500;
  background: #f9f9f9;
  color: #474e58;
}
