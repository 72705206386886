.container {
  display: flex;
  align-items: center;
  cursor: default;

  padding: 20px;
  max-width: 460px;
  width: 100%;
  flex-direction: column;

  &:hover {
    .body .menu {
      opacity: 1;
    }
  }

  .message {
    text-align: center;
    line-height: 1.5;

    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    padding-top: 8px;
  }

  .body {
    position: relative;

    .menu {
      position: absolute;
      opacity: 0;
      top: 0;
      right: 0;
      transform: translateX(100%);
      transition: all 300ms;
    }
  }

  .date {
    font-size: 10px;
    opacity: 0.8;
    width: 100%;
  }
}
