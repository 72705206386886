.container {
  position: relative;
  height: 0;
  border-radius: 6px;
  overflow: hidden;

  padding-bottom: 56%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
