.item {
  background: #fff;
  border: 0;
  padding: 6px 12px;
  line-height: 1;
  margin-right: 4px;
  font-family: var(--font-family);
  font-size: 13px;
  min-width: 120px;
  color: var(--primary-text-color);
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: #f0f0ff;
  }
}

.items {
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: #f0f0ff;
}
