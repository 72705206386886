.name {
  line-height: 1;
  font-weight: 500;
}

.date {
  font-size: 10px;
  text-align: right;
  color: var(--secondary-text-color);
}

.component {
  background-color: #f2f2f5;
  padding: 8px 12px;
  border-radius: 10px;
}
