.blue {
  --theme-color: rgb(82, 90, 249);
  --theme-color-10: rgba(82, 90, 249, 0.1);
}

.amber {
  --theme-color: rgb(254, 171, 65);
  --theme-color-10: rgba(254, 171, 65, 0.1);
}

.green {
  --theme-color: rgb(60, 159, 60);
  --theme-color-10: rgba(60, 159, 60, 0.1);
}

.red {
  --theme-color: #ea1a55;
  --theme-color-10: rgba(#ea1a55; 0.1);
}
