.container {
  position: relative;
  margin-top: 0.25em;
  margin-bottom: 0.25em;

  &:hover {
    .controls {
      opacity: 1;
    }
  }
}

.selected {
  position: relative;

  &:after {
    position: absolute;
    content: " ";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    border-radius: 10px;
    border: 2px solid #7a7aff;
    outline: 4px solid #f0f0ff;
    z-index: 2;
  }
}
