.frame {
  display: flex;
  background: #f2f2f5;
  height: 200px;
  width: 200px;
  border-radius: 6px;
  overflow: hidden;
}

.frameInner {
  height: 200px;
  width: 200px;
  box-sizing: border-box;
}

.content {
  display: flex;
  min-width: 0;
  overflow: hidden;

  background-position: top center;
  background-size: cover;

  width: 200px;
  height: 200px;
}
