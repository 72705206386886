.container {
  display: flex;
  position: relative;
  justify-content: center;
  padding: 12px;
}

.body {
  display: block;
  position: relative;
}

.avatar {
  overflow: hidden;
  border-radius: 4px;
}

.checkerboard {
  background-size: 20px 20px;
  background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      45deg,
      rgb(250, 249, 251) 25%,
      rgba(0, 0, 0, 0) 25%
    ),
    linear-gradient(-45deg, rgb(250, 249, 251) 25%, rgba(0, 0, 0, 0) 25%),
    linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, rgb(250, 249, 251) 75%),
    linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, rgb(250, 249, 251) 75%);
}

.removeButton {
  position: absolute;
  padding: 6px;
  top: 0;
  right: -6px;
  cursor: pointer;
  transform: translateX(100%);
  border-radius: 100px;
  background: #f6f6f6;

  &:hover {
    background-color: #f3f3f3;
  }
}
