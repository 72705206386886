.component {
  display: inline-flex;

  .button {
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    color: #63627d;
    border-radius: 8px;
    padding: 11px 20px;
    cursor: pointer;
    // min-width: 100px;
    line-height: 1;

    &:hover:not(.selected) {
      background: #f9f9f9;
    }
  }

  .selected {
    background: #f9f9f9;
    color: #474e58;
    font-weight: 600;
    // color: var(--theme-color);
    // background-color: var(--theme-color-10);
  }
}
