.iconWrapper {
  width: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--placeholder-color);
  transition: color 0.2s;

  &:hover {
    color: #777;
  }

  &.active {
    color: #333;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 32px;

  color: var(--placeholder-color);
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: #777;
  }

  &.active {
    color: #333;
  }
}
