:root {
  --primary-color: #333;
  --primary-color-10: rgba(51, 51, 51, 0.1);
  --primary-color-30: rgba(51, 51, 51, 0.3);
  --primary-color-50: rgba(51, 51, 51, 0.5);

  --primary-text-color: #374750;
  --secondary-text-color: #87939f;
  --placeholder-color: #c9c9d9;
  --placeholder-color-hover: #babac9;
  --hover-color: #f0f0ff;
  /* Default theme colour */
  --theme-color: #333;
  --hover-color-50: rgba(#f0f0ff, 0.5);

  --font-size: 14px;

  --container-border-radius: 6px;

  --font-family: "Wotfard", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  --window-nav-width: 72px;
  --window-content-width: calc(100vw - 72px);
  --border-default: 1px solid #ebecef;

  --editor-header-height: 60px;
  --editor-content-height: calc(100vh - 60px);
  --editor-page-container-height: calc(100vh - 120px);
  --editor-page-container-width: 720px;
  --editor-border-default: 1px solid #ebecef;
}
