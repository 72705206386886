.hero {
  position: relative;
  margin: 6px;
  border-radius: 6px;
  background-color: #fff;
  background-color: var(--post-title-background-color);

  .heroBody {
    padding-top: 96px;
    padding-bottom: 96px;

    margin: 0 auto;
    max-width: var(--editor-page-container-width);
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .hero {
    margin: 0;

    .heroBody {
      padding-top: 36px;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.titleContainer {
  position: relative;
  padding-top: 6px;
  padding-bottom: 12px;
  line-height: 44px;
}

.title {
  font-weight: 600;
  font-size: 44px;
  line-height: normal;
  // font-family: "Merriweather";
}

.break {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  align-self: center;
  background-color: black;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}
