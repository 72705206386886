.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  line-height: 1;
}

.bold {
  font-weight: bold;
}

.square {
  height: 1em;
  width: 1em;
}

.inline {
  display: inline-flex;
}
