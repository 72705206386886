.logoContainer {
  position: relative;
}

.title {
  display: inline-flex;
  position: relative;

  font-size: 32px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }
}

.description {
  color: var(--secondary-text-color);
  max-width: 400px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}
