.wrapper {
  position: relative;
}

.container {
  display: block;
  position: relative;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .title {
      font-size: 36px;
    }
  }
}

.text {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  position: relative;

  margin: 0 auto;
  max-width: var(--editor-page-container-width);
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

.comments {
  margin: 0 auto;
  max-width: 740px;
  width: 100%;

  padding-left: 12px;
  padding-right: 12px;
}

.publishBanner {
  padding-top: 20px;
}
