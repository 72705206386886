.split {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

.center {
  align-items: center;
}

.baseline {
  align-items: baseline;
}
