.element {
  position: absolute;
  transform: translateY(4px);

  min-width: 100%;

  right: 0;

  z-index: 100;
  background: white;
  cursor: pointer;

  border-radius: 8px;
  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  text-align: left;
  font-weight: normal;
}

.left {
  left: 0;
}

.right {
  right: 0;
}
