.block {
  display: block;
  position: relative;

  padding: 8px 12px;
  padding-right: 32px;
  border-radius: 8px;

  min-width: 120px;
  max-width: 200px;

  background-color: #fff;
  border: var(--border-default);

  &:hover {
    cursor: pointer;
    background-color: #f9f9f9;
  }

  .name {
    font-weight: 700;
    font-size: 11px;
    color: var(--secondary-text-color);
    text-transform: uppercase;
  }

  .edit {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    width: 32px;
    height: 32px;

    margin-left: 20px;
    line-height: 1;
  }

  .value {
    font-weight: 400;
    font-size: 13px;
  }
}
