.form {
  padding: 12px;
  max-width: 500px;
  width: 100%;
}

.container {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
