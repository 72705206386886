@for $i from 0 through 20 {
  .distance-horizontal-#{$i} {
    $distance: $i;

    & > * {
      margin: 0 #{$distance}px !important;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }

    &.hasEdges {
      padding: 0 #{$distance * 2}px;
    }
  }
}

@for $i from 0 through 20 {
  .distance-vertical-#{$i} {
    $distance: $i;

    & > * {
      margin: #{$distance}px 0 !important;

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    &.hasEdges {
      padding: #{$distance * 2}px 0;
    }
  }
}
