.popover {
  display: flex;
  padding: 6px;
  border-radius: 0.5rem;

  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  background-color: #fff;
}

.button {
  display: flex;
  border: none;
  background: none;
  font-weight: 400;
  // font-size: 12px;
  line-height: 1;
  padding: 6px 8px;
  opacity: 1;
  cursor: pointer;
  border-radius: 4px;

  &:hover,
  &.active {
    background-color: #f3f3f3;
  }
}

.item {
}
