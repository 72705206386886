.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 400;
  line-height: 1;
  padding-left: 14px;
  padding-right: 14px;
  transform-origin: center;
  transition: transform 300ms;
  text-decoration: none;
  color: #fff;
  background-color: #292929;

  i {
    line-height: 1;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.98);
  }
}

.fill {
  width: 100%;
}

.link {
  text-decoration: none;
}

.square {
  border-radius: 8px;
}

.secondary {
  background: #fff;
  border: 1px solid #ddd;
  color: var(--primary-text-color);
}

.children {
  white-space: nowrap;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.loading {
  position: relative;
  color: transparent;
}

.loading::after {
  display: block;
  position: absolute;
  background: transparent;

  content: " ";

  top: calc(50% - 9px);
  left: calc(50% - 9px);

  border: 2px solid rgba(#fff, 0.2);
  border-top: 2px solid #fff;
  border-radius: 50%;

  width: 16px;
  height: 16px;

  z-index: 10;

  animation: spin 1.2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.medium {
  height: 32px;
}

.large {
  height: 50px;
  font-weight: 500;
}
