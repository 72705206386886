.indicator {
}

.green {
  color: #34be82;
}

.component {
  line-height: 1.4;
  font-size: 12px;
}
