.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  position: relative;
  width: 36px;
  height: 36px;
}
