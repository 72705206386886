.popover {
  // display: flex;
  padding: 8px;
  border-radius: 8px;

  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  background-color: #fff;
}

.item {
  font-weight: 400;
  line-height: 1;
  padding: 12px 16px;

  cursor: pointer;
  border-radius: 6px;

  &:hover,
  &.active {
    background-color: var(--hover-color);
  }
}
