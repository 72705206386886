@import "styles/mixins.scss";

.container {
  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

.input {
  @include looks-like-input;
  cursor: pointer;
}

.placeholder {
  color: var(--placeholder-color-hover);
}
