.tag {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;

  background-color: #f2f2f5;
  border-radius: 8px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 10px;
}

.text {
  line-height: 1;
}
