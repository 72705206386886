.container {
  width: 100%;

  padding-top: 10px;
  padding-bottom: 20px;
}

.button {
  display: block;
  position: relative;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
}
