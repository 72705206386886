.content {
  margin: 0 auto;
  width: 100%;
  max-width: 800;
}

.contentInner {
  padding: 20px 0px;
  max-width: 720px;
  margin: 0 auto;
}
