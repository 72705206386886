.link {
  text-decoration: none;
  text-transform: none;
}

.view {
  height: 100vh;
  width: 100%;
}

.header {
  display: flex;
  height: 60px;
  width: 100%;
  background-color: #fff;
}

.body {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.bodyInner {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content {
  width: 100%;
  margin: 0 auto;
}
