.container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 250px;
  border-radius: 12px;
  background: #f9f9f9;
  transition: transform 300ms, color 300ms, box-shadow 300ms;

  &:hover {
    box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);

    transform: translateY(-2px);
  }

  .image {
    display: flex;
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 12px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: 600;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
