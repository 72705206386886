.component {
  width: 100%;
}

.label {
  font-weight: 500;
  line-height: 1.5;

  // font-size: 13px;

  // font-size: 11px;
  // letter-spacing: 1px;
  // text-transform: uppercase;

  font-size: 12px;
  // opacity: ;
  // letter-spacing: 0.5px;
  // text-transform: uppercase;
}
