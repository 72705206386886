.thread {
  width: 300px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px;

  z-index: 100;
}
