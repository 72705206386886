.component {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: var(--border-default);
}

.title {
  flex: 1;
}
