.details {
  color: #bbb;
  text-transform: capitalize;
}

.title {
  font-weight: 500;
}

.description {
  font-size: 12px;
}

.role {
  line-height: 1;
  padding: 4px 10px;
  background-color: rgb(242, 242, 245);
  color: #868686;
  border-radius: 8px;
  font-weight: 500;
  font-size: 10px;

  text-transform: capitalize;
}
