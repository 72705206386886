.container {
  position: relative;
  padding: 12px 20px;
  background-color: #f9f9f9;
  border-radius: 6px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: var(--primary-text-color);

  p {
    margin: 0;
  }
}

.warning {
  background-color: #fdefc3;
}

.error {
  background-color: #f8d0c8;
}

.info {
  background-color: #e8f7ee;
}
