@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&family=DM+Serif+Display&family=Playfair+Display:wght@400;500;600;700&display=swap");

.ProseMirror {
  max-width: 800px;
  min-height: 100%;
  color: var(--primary-text-color);
  outline: none;
  font-size: 16px;
  font-smooth: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::selection {
    background: #f0f0ff;
  }

  p {
    position: relative;
    line-height: 1.5;
    padding: 0.25em 0;
    margin-block-start: 0em;
    margin-block-end: 0em;
    border-radius: 4px;
    transition: background-color 300ms;
  }

  ul,
  ol {
    li {
      padding-left: 0.5rem;
    }
  }

  a.editor-simple-link {
    color: #17afed;
  }
}

.text-editor {
  .ProseMirror {
    padding-bottom: 100px;
  }

  .ProseMirror[contenteditable="true"] {
    p,
    h1,
    h2 {
      &:not(.selected).has-focus {
        background: linear-gradient(90deg, #fcfcfc, #f8f8f8);
      }

      &:not(.has-focus):hover {
        background: #fbfbfb;
      }
    }

    p::after {
      display: flex;
      align-items: center;
      position: absolute;
      // content: 'Type "/" for commands';
      content: "";
      font-family: var(--font-family);
      font-size: 12px;
      color: var(--secondary-text-color);
      right: 0;
      top: 0;
      bottom: 0;
      padding-right: 8px;
      text-align: right;
      pointer-events: none;
      z-index: 1;
      opacity: 0;
      transition: all 600ms;
      transform: translateX(5px);
    }

    p.empty.has-focus:not(.selected)::after {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 600ms;
    }
  }
}

.text-reader {
  .ProseMirror[contenteditable="false"] {
    p {
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }
  }
}
