.container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  padding-left: 20px;

  z-index: 2;
  transition: all 300ms;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.buttons {
  display: flex;
  align-items: center;
  background: rgb(255, 255, 255);
}

.button {
  cursor: pointer;
  padding: 6px 8px;
  transition: color 300ms;
  color: #c9c9d9;

  &:hover {
    color: var(--primary-text-color);
  }
}
