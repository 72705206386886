.frame {
  display: flex;
  background: #f2f2f5;
  height: 200px;
  width: 200px;
  border-radius: 6px;
  overflow: hidden;
}

.frameInner {
  height: 200px;
  width: 200px;
  box-sizing: border-box;
}

.content {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  min-width: 0;
  overflow: hidden;

  background-position: top center;
  background-size: cover;

  width: 200px;
  height: 200px;

  &:hover {
    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;

      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.zone {
  cursor: pointer;
}
