.editEmoji {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 40px 0;
  border-radius: 6px;

  .emojiContainer:hover {
    position: relative;
    background-color: #f0f0ff;
  }

  .emojiContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    width: 100px;
    height: 100px;
    background: #f9f9f9;
  }

  .emoji {
    font-size: 60px;
    user-select: none;
  }

  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    color: var(--secondary-text-color);
    text-align: center;
    padding: 8px;
  }
}
