.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: var(--window-nav-width);
  height: 100%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    display: none;
  }
}

.column {
  align-items: center;

  padding-bottom: 20px;
  padding-top: 18px;
}

.svg {
  cursor: pointer;
}

.logo {
  position: relative;
  fill: var(--theme-color);
  transition: all 300ms;
  z-index: 2;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  border-radius: 8px;
  text-transform: none;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
}
