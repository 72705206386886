.addButton {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  cursor: pointer;

  .description {
    color: var(--secondary-text-color);
    font-size: 12px;
  }
}

.container {
  position: relative;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  font-family: var(--font-family);
  font-weight: 400;
  border: var(--border-default);
  border-radius: 10px;
}

.question {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
}

.multiple_choice .question {
  border-bottom: var(--border-default);
}

.answers {
  padding-top: 8px;
  padding-bottom: 8px;
}

.answer {
  width: 100%;
  padding: 8px;

  &.button {
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #fcfcfe;
    }
  }

  .icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 24px;
    height: 24px;
    line-height: 1;
    border-radius: 4px;
    border: var(--border-default);
    color: var(--secondary-text-color);

    font-size: 12px;
    font-weight: 600;
  }

  .message {
    flex: 1;
    padding-left: 8px;
  }

  .quantity {
    width: 70px;
    white-space: nowrap;
    text-align: right;

    font-size: 12px;
    font-weight: 500;
  }
}

.answered {
  .answer {
    &.chosen {
      .icon {
        border: 1px solid #16c39a;
        color: #16c39a;
      }
    }
  }
}
