body,
html,
#root {
  margin: 0;
  padding: 0;

  min-height: 100vh;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  color: var(--primary-text-color);
  font-size: 14px;

  font-family: var(--font-family);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

hr {
  border: 0;
  border-bottom: var(--border-default);
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
}

b {
  font-weight: 500;
}

input,
button,
section {
  font-family: inherit;
}

a {
  color: inherit;
  font-weight: 500;
}
