.component {
  display: block;
  position: relative;
  font-family: var(--font-family);
  border: var(--border-default);
  padding: 12px;
  border-radius: 10px;
}

.viewer {
  cursor: pointer;

  &:hover {
    background: #fcfcfe;
  }
}

.addButton {
  text-align: center;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;

  .description {
    color: var(--secondary-text-color);
    font-size: 12px;
  }
}

.metric {
  text-align: center;

  .title {
    color: var(--secondary-text-color);
  }

  .value {
    font-size: 60px;
  }
}
