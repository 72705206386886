.emoji {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 60px;
  width: 100px;
  height: 100px;

  background: #f9f9f9;
}
