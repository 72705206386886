.projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  padding-bottom: 16px;
}

.multi {
  justify-content: center;
}

.create {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  height: 250px;
  width: 250px;

  cursor: pointer;

  border-radius: 12px;
  background: radial-gradient(#fff, #f9f9f9);
  transition: all 300ms;

  &:hover {
    box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);

    transform: translateY(-2px);

    .createIcon {
      transform: scale(1.5);
      padding: 10px;
    }
  }

  .createIcon {
    transition: all 300ms;
    transform: scale(1);
    padding: 0px;
  }

  .createLabel {
    color: var(--secondary-text-color);
  }

  .createIconWrapper {
    flex-direction: column;

    transition: all 300ms;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 100%;
    border-radius: 100px;
    color: #888;
  }
}
