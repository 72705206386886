.element {
  display: flex;
  align-items: center;
  position: relative;
  border: 0;
  background-color: #333;
  border-radius: 6px;
  font-family: inherit;
  color: #fff;
  white-space: nowrap;
  outline: none;
  font-size: inherit;
  justify-content: center;

  line-height: 1;

  &:disabled {
    pointer-events: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.3;
  }

  & > *:last-child:not(:first-child) {
    padding-left: 6px;
  }
}

.loading {
  color: transparent;
}

.loading:after {
  display: block;
  position: absolute;
  background: transparent;

  content: " ";

  top: calc(50% - 9px);
  left: calc(50% - 9px);

  border: 2px solid rgba(#fff, 0.2);
  border-top: 2px solid #fff;
  border-radius: 50%;

  width: 16px;
  height: 16px;

  animation: spin 1s infinite;
}

.primary {
  &:hover {
    background-color: #222;
  }

  &:active {
    background-color: #444;
  }
}

.secondary {
  background: white;
  color: #444;
  background: #f3f3f3;

  &:hover {
    background: #e9e9e9;
  }

  &:active {
    background: #e0e0e0;
  }
}

.tertiary {
  background: white;
  color: inherit;
  border: 1px solid #c5c5c5;
  font-weight: 400;

  &:hover {
    border: 1px solid #999;
  }

  &:active {
    background: #f9f9f9;
  }
}

.dashed {
  background-color: transparent;
  color: #444;
  border: 1px dashed #aaa;
  font-weight: 400;

  &:hover {
    border-color: #333;
    color: #111;
    // background-color: #eee;
  }
}

.medium {
  padding: 11px 20px;
  font-weight: 500;
}

.large {
  padding: 12px 24px;
  font-weight: 500;
}

.small {
  padding: 4px 8px;
  font-weight: 400;
}

.fill {
  width: 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  line-height: 1;
  // padding-right: 6px;
}

.text {
  // flex: 1;
  text-align: center;
}
