.card {
  background: #fff;
  padding: 20px;
  // padding-left: 0;
  // padding-right: 0;
  border-radius: var(--container-border-radius);
  border-radius: 12px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  line-height: 1;

  width: 100%;
}

.center {
  text-align: center;
}

.header {
  padding-bottom: 12px;
}
