.button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  cursor: pointer;

  transition: all 300ms;
  transform-origin: center;

  &:not(.active):hover {
    .icon {
      background-color: #f9f9f9;
    }
  }

  .icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background-color: #fff;

    padding: 2px;
    height: 30px;
    width: 30px;
    border-radius: 4px;
  }
}

.active {
  .icon {
    background-color: #f0f0ff;
  }
}

.tooltip {
  color: #fff;
  background-color: rgb(75, 75, 75);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
}

.disabled {
  opacity: 0.3;
}
