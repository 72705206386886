.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline {
  display: inline-flex;
}

.fill {
  width: 100%;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.middle {
  justify-content: center;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

.center {
  align-items: center;
}

.baseline {
  align-items: baseline;
}

.cell {
  display: flex;
}

.stackOnPhones {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    flex-direction: column;
    & > * {
      margin: 4px !important;
      width: 100% !important;
    }
  }
}
