.zone {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 0;
  box-shadow: inset 0 0 0 1px var(--border, #d0d0df);
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 1px;
  overflow: hidden;

  &:hover {
    border-color: #dae1ed;
  }
}

.background {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  height: 100%;

  padding: 12px;

  background-size: 20px 20px;
  background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      45deg,
      rgb(250, 249, 251) 25%,
      rgba(0, 0, 0, 0) 25%
    ),
    linear-gradient(-45deg, rgb(250, 249, 251) 25%, rgba(0, 0, 0, 0) 25%),
    linear-gradient(45deg, rgba(0, 0, 0, 0) 75%, rgb(250, 249, 251) 75%),
    linear-gradient(-45deg, rgba(0, 0, 0, 0) 75%, rgb(250, 249, 251) 75%);
}

.placeholder {
  width: 100%;
  padding: 12px;
  text-align: left;
  color: var(--placeholder-color-hover);
}

.frame {
  height: 128px;
  width: 128px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
