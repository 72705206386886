.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 136px;
  height: 136px;
  background-color: #fff;

  .image {
    position: relative;

    width: 130px;
    height: 130px;
    background-color: #f9f9f9;
    border-radius: 8px;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;

      content: attr(data-name);
      color: var(--theme-color);
      font-size: 40px;
      font-weight: 800;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
