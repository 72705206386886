.container {
  display: flex;

  flex-direction: column;
  font-family: var(--font-family);
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1em;

  &.editable {
    & .content:hover {
      background: #fbfbfb;
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: auto;
    line-height: 1.5;
    cursor: text;
    font-size: 2.2em;
    font-weight: 600;
  }

  .empty {
    &::after {
      position: absolute;
      content: "Title...";
      color: var(--placeholder-color);
      left: 0;
      top: 0;
    }
  }
}

.blocks {
  padding-top: 12px;
  border-radius: 4px;
  background-color: #fff;
}
