.window {
  display: flex;
  flex-direction: row;
  position: relative;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content {
  width: var(--window-content-width);

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 100%;
  }

  height: 100vh;

  z-index: 2;

  box-shadow: 0 6px 18px 0 rgba(0, 35, 90, 0.08),
    0 0 2px 0 rgba(0, 35, 90, 0.06);

  overflow: auto;
}
