.container {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: row;
  font-family: var(--font-family);
  font-size: 14px;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.comments {
  background-color: #f6f6f6;
  line-height: 1;
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}
