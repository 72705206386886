.component {
  position: relative;

  &:hover .tooltip {
    display: block;
  }

  .tooltip {
    display: none;
  }
}

.tooltip {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  color: #fff;
  background-color: rgb(75, 75, 75);
  transform: translate3d(0, 0, 0);
  transform-origin: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  width: auto;
  white-space: nowrap;

  animation-duration: 300ms;
  animation-name: appear;
  animation-iteration-count: 1;
}

.right {
  left: calc(100% + 8px);
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.left {
  // left: calc(100% + 8px);
  // top: 50%;
  // transform: translate3d(0, -50%, 0);
}

.top {
  left: 50%;
  transform: translate3d(-50%, -10px, 0);
  top: -100%;
}

.bottom {
  left: 50%;
  transform: translate3d(-50%, 10px, 0);
  top: 100%;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
