.reaction {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  line-height: 1;
  padding: 3px 4px;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;

  .count {
    font-size: 12px;
    font-weight: 500;
    padding-left: 6px;
  }

  .emoji {
    font-size: 14px;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &.active {
    background-color: rgb(241, 248, 255);
    border-color: rgb(219, 237, 255);
  }
}
