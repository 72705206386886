.wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.image {
  display: block;
  position: relative;

  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
}
